import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Banner, Tiles, Container, List, Skill } from '../blocks'; // import ModalWrapper from "./modal.js";

import Projects from '../projects.js';
import BlogIntros from '../blogIntros.js';
import { Link } from 'gatsby';
export const wide = true;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  wide,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Banner mdxType="Banner">
      <h1>{`Viraj Chavan`}</h1>
      <p>{`Product-Minded Full-Stack Software Engineer`}</p>
      <Skill mdxType="Skill">Ruby On Rails</Skill>
      <Skill mdxType="Skill">JavaScript</Skill>
      <Skill mdxType="Skill">TypeScript</Skill>
      <Skill mdxType="Skill">ReactJs</Skill>
      <Skill mdxType="Skill">NodeJs</Skill>
      <Skill mdxType="Skill">MongoDB</Skill>
      <Skill mdxType="Skill">MySQL</Skill>
      <br /><br />
      <ul>
        <li parentName="ul"><a href="/resume.pdf" target="_blank">Resume</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/blog"
          }}>{`Blog`}</a></li>
        <li parentName="ul"><a href="https://github.com/virajvchavan" target="_blank">GitHub</a></li>
        <li parentName="ul"><a href="https://www.linkedin.com/in/virajvchavan/" target="_blank">Linkedin</a></li>
        <li parentName="ul"><a href="https://dev.to/virajvchavan" target="_blank">Dev.to</a></li>
      </ul>
      <div style={{
        "marginTop": "6em"
      }}>
    <h3 style={{
          "marginLeft": "10px"
        }}>I like to build things.</h3>
    <Projects mdxType="Projects" />
      </div>
      <div style={{
        "marginTop": "6em"
      }}>
    <h3 style={{
          "marginLeft": "10px"
        }}>I like to write.</h3>
    <BlogIntros mdxType="BlogIntros" />
      </div>
      <div style={{
        "marginTop": "6em"
      }}>
    <h3 style={{
          "marginLeft": "10px"
        }}>I like to read.</h3>
    <Link to="/readings" className="booksLink" style={{
          marginLeft: '20px',
          textDecoration: 'underline',
          fontSize: '16px'
        }} mdxType="Link">See the list of books I have been reading.</Link>
      </div>
    </Banner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      